import React, {Fragment} from "react";
import {Row, Col, Card, CardBody, BreadcrumbItem} from "reactstrap"
import ReferenceValueRepository from "@repository/ReferenceValueRepository";
import {DIALOG_TYPE, HTTP_RESPONSE} from "@utils/constants";
import LoadingContainer from "@components/utilities/LoadingContainer";
import DialogBoxComponent from "@components/utilities/DialogBoxComponent";
import "../loader/loader.css";
import ActionsFormatter from "../utilities/ActionsFormatter";
import PaginationWrap from "../utilities/PaginationWrap";
import { withTranslation } from "react-i18next";

class ReferenceValueList extends React.Component<any, any> {
    repo : ReferenceValueRepository;
    cieId: any;
    /**
     *
     * @param props
     */
    constructor(props:any) {
        super(props);
        this.repo = new ReferenceValueRepository(props.apiUrl);
        this.cieId = props.cieId;
        this.state = {
            items: [],
            total: 0,
            entity: null, // element courant
            itemPerpage: 10,
            loading: true,
            loadingSearch: false,
            query: "",
            showConfirmDialog: false,
            pageSizeOptions: {
                totalSize: 0, // replace later with size(customers),
                custom: true,
                onPageChange: (page : number) => this.onPageChange(page),
                onSearchChange: this.handleSearchChange,
                page: 1,
                sizePerPage: 10,
                pageStartIndex: 1,
                sizePerPageList: 10,
                showTotal: true,
                onSizePerPageChange: {}
            },
            dialog: {
                show: false,
                onConfirm: () => {
                },
                handleClose: () => {
                },
                message: "",
                type: DIALOG_TYPE.ALERT
            }
        };
        this.onPageChange = this.onPageChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.findAll();
    }

    onPageChange(page : number) {
        this.setState(
            (state:any) => ({
                ...state,
                loading: true,
                page: page,
            }),
            () => {
                this.findAll(page);
            }
        );
    }

    handleDelete(e: any, elt: any) {
        e.preventDefault();
        this.setState(
            (state : any) => ({
                ...state,
                entity: elt,
                dialog: {
                    ...state.dialog,
                    onConfirm: () => this.performDelete(),
                    handleClose: () => this.closeDialogModal(),
                    type: DIALOG_TYPE.CONFIRM_DELETION
                }
            }),
            () => {
                this.toggleDialogModal();
            }
        );
    }

    handleSearchChange(query: any) {
    }

    findAll(page = 1) {
        this.repo.findAll(page, this.state.itemPerpage, this.state.query, this.props.referenceId,this.cieId).then((data: { items: any; totalCount: any; }) => {
            this.setState(
                (state:any) => ({
                    ...state,
                    items: data.items,
                    total: data.totalCount,
                    pageSizeOptions: {
                        sizePerPage: this.state.itemPerpage,
                        totalSize: 10, // replace later with size(customers),
                        custom: true,
                        onPageChange: (page: number) => this.onPageChange(page),
                        onSearchChange: this.handleSearchChange
                    },
                }),
                () => {
                    this.setState((state:any) => ({...state, loading: false, loadingSearch: false}));
                }
            );
        }).catch((response: any) => {
            this.catchError(response);
        });
    }

    handleTableChange = (type: string, {searchText, page, sizePerPage, filters, sortField, sortOrder, cellEdit}: any) => {
        if (type === 'pagination') {
        } else if (type === 'sort') {

        } else if (type === 'filter') {

        } else if (type === 'search') {
            this.setState(
                (state:any) => ({
                    ...state,
                    query: searchText
                }),
                () => {
                    this.findAll();
                }
            );
        }
    }

    toggleDialogModal = () => {
        this.setState((state:any) => ({
            ...state,
            loading: false,
            dialog: {...state.dialog, show: !state.show}
        }));
    }

    closeDialogModal = () => {
        this.setState((state:any) => ({
            ...state,
            loading: false,
            dialog: {...state.dialog, show: false}
        }));
    }

    performDelete = () => {
        if (this.state.entity.id) {
            this.setState(
                (state:any) => ({
                    ...state,
                    loading: true,
                }),
                () => {
                    const idToRemove = this.state.entity.id;
                    this.repo.remove(this.state.entity.id).then((data: any) => {
                        this.setState((state:any) => ({
                            ...state,
                            loading: false,
                            id: null,
                            total: state.total - 1,
                            items: state.items.filter((elt: { id: any; }) => elt.id !== idToRemove)
                        }));
                        this.closeDialogModal();
                    }).catch((response: any) => {
                        this.catchError(response);
                        this.closeDialogModal();
                    });
                }
            );
        }
    }

    catchError = (response: { status: any; data: { message: string; }; }) => {
        const dialog = {
            ...this.state.dialog,
            show: true,
            onConfirm: () => this.closeDialogModal(),
            handleClose: () => this.closeDialogModal(),
            type: DIALOG_TYPE.ALERT
        }
        switch (response.status) {
            case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
                dialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_NOT_FOUND: {
                dialog.type = DIALOG_TYPE.ALERT;
                break;
            }
            case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
                let message = '';
                if (response.data.message) {
                    message = response.data.message;
                }
                dialog.type = DIALOG_TYPE.SERVER_ERROR;
                break;
            }
            default:
        }
        this.setState((state:any) => ({
            ...state,
            dialog: dialog,
        }));
    }

    /**
     *  Methode appelée quand la valeur d'un champ de formulaire change
     *
     * @param target
     */
    handleFormInputChange = ({target} : any) => {
        const {name, value} = target;
        this.setState(
            (state:any) => ({
                ...state,
                [name]: value,
                loadingSearch: true
            }), () => {
                this.findAll();
            }
        );
    };

    handleOnPagePerPageChange = (p: number) => {
        this.setState(
            (state:any) => ({
                ...state,
                itemPerpage: p,
                page: 1,
                loading: true,
                pageSizeOptions: {
                    ...state.pageSizeOptions,
                    sizePerPage: p,
                    page: 1,
                }
            }),
            () => {
                this.setState(
                    (state:any) => ({
                        ...state,
                        loading: true
                    }), () => {
                        this.findAll();
                    }
                );
            }
        );
    }

    render() {
        return <Fragment>
            <LoadingContainer loading={this.state.loading}>
                <Card>
                    <CardBody>
                        <div className="mb-4">
                            <Row>
                                <Col xs="12">
                                    <div className="page-title-box justify-content-between">
                                        <div className="">
                                            <ol className="breadcrumb m-0">
                                                <BreadcrumbItem>
                                                    <this.props.Link style={{marginLeft: "15px"}} className="btn" to={this.props.backPath}> <i className='bx bx-chevron-left'></i> {this.props.t("REFERENCES")}</this.props.Link>
                                                </BreadcrumbItem>
                                                <BreadcrumbItem className="d-flex align-items-center">
                                                    <this.props.Link to="#"> {this.props.t("REFERENCE_VALUES")} </this.props.Link>                                                    
                                                </BreadcrumbItem>
                                            </ol>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md="8">
                                    { 
                                        this.props.showAdd &&
                                        (
                                            <this.props.Link className="btn btn-primary" to={this.props.addUrl}> {this.props.t("ADD_REFERENCE_VALUE")} </this.props.Link>
                                        )
                                    }
                                </Col>
                                <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                                        <form className="search-box d-inline-block">
                                            <div className="position-relative">
                                                <label htmlFor="search-bar" className="search-label">
                                                    <span id="search-bar-label" className="sr-only"> {this.props.t("FIND_IN_TABLE")}</span>
                                                    <input
                                                        id="search-bar"
                                                        type="text"
                                                        name="query"
                                                        className="form-control "
                                                        placeholder={this.props.t("SEARCH")}
                                                        onChange={(e) => this.handleFormInputChange(e)}
                                                        value={this.state.query}/>
                                                </label>
                                                <i className="bx bx-search-alt search-icon"/>
                                            </div>
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <PaginationWrap
                            page={this.state.page}
                            paginationProps={this.state.pageSizeOptions}
                            handleOnPagePerPageChange={(p: any) => this.handleOnPagePerPageChange(p)}
                            itemPerpage={this.state.itemPerpage}
                            onPageChange={(p: number) => this.onPageChange(p)}
                            totalItems={this.state.total}
                        />
                        <LoadingContainer loading={this.state.loadingSearch}>
                            <Row>
                                <Col xl="12">
                                    <div className="table-responsive">
                                        <div className="react-bootstrap-table">
                                            <table
                                                className="table table align-middle table-nowrap">
                                                <thead className="thead-light">
                                                <tr>
                                                    <th tabIndex={0}> {this.props.t("CODE")}</th>
                                                    <th tabIndex={0}> {this.props.t("DESCRIPTION")}</th>
                                                    <th tabIndex={0}> {this.props.t("IS_ACTIVE")} </th>
                                                    <th tabIndex={0}> {this.props.t("ASSOCIATED_REFERENCE")}</th>
                                                    <th tabIndex={0}> {this.props.t("ASSOCIATED_COMPANY")}</th>
                                                    <th tabIndex={0}> {this.props.t("PARENT_VALUE_SAP")}</th>
                                                    <th tabIndex={0}> {this.props.t("ASSOCIATED_LABELS")}</th>
                                                    <th tabIndex={0}/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.items.length > 0 && this.state.items.map((item: any, index: number) =>
                                                    <tr key={index}>
                                                        <td>{item.code || ""}</td>
                                                        <td>{item.description || ""}</td>
                                                        <td>{ (item.isActive && item.isActive==1) ? "oui" : "non"}</td>
                                                        <td> {item.reference ? item.reference.name : ""} </td>
                                                        <td> {item.company ? item.company.name : ""} </td>
                                                        <td> {item.parentReferenceValue ? item.parentReferenceValue.code : ""} </td>
                                                        <td><this.props.Link style={{marginLeft: "15px"}} className="btn" to={this.props.basePath + item.id}> <i className="bx bxs-show action-icon"/> </this.props.Link></td>
                                                        <td>
                                                            <ActionsFormatter
                                                                item={item}
                                                                onDelete={(event: any, elt: any) => this.handleDelete(event, elt)}
                                                                path={this.props.path}
                                                                Link={this.props.Link}
                                                                showEdit ={this.props.showEdit} showView ={this.props.showView} showDelete ={this.props.showDelete}
                                                            />
                                                        </td> 
                                                    </tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </LoadingContainer>
                        <PaginationWrap
                            page={this.state.page}
                            paginationProps={this.state.pageSizeOptions}
                            handleOnPagePerPageChange={(p: any) => this.handleOnPagePerPageChange(p)}
                            itemPerpage={this.state.itemPerpage}
                            onPageChange={(p: number) => this.onPageChange(p)}
                            totalItems={this.state.total}
                        />
                    </CardBody>
                </Card>
            </LoadingContainer>
            <DialogBoxComponent
                handleClose={() => this.closeDialogModal()}
                handleConfirm={() => this.state.dialog.onConfirm()}
                show={this.state.dialog.show}
                type={this.state.dialog.type}
            />
        </Fragment>;
    }
}

export default withTranslation()(ReferenceValueList)