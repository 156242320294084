import React, {Fragment, FunctionComponent} from "react";
import {Button, Modal} from "react-bootstrap";
import ParseHtml from "@components/utilities/ParseHtml";

interface PropsType {
    enabledLoading: boolean,
    show: boolean,
    handleClickSaveButton: any,
    handleClickSecondSaveButton?: any,
    handleClose: any,
    title: string,
    saveTitle?: string,
    secondSaveTitle?: string,
    closeTitle?: string,
    cssClass?: string,
    textFooter?: string,
    cssConfirmBtnClass?: string,
    cssSecondConfirmBtnClass?: string,
    children?: any
    centered?: boolean
    useFooter?: boolean
    useHeader?: boolean
    enableConfirmBtn?: boolean
    enableSecondConfirmBtn?: boolean
    size?: 'lg'|'sm'|'xl';
    center?: 'lg'|'sm'|'xl';
}

/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const AppModalComponent: FunctionComponent<PropsType> = (props: PropsType) => {
    const {
        enabledLoading = false,
        textFooter=null,
        size='lg',
        useHeader =true,
        useFooter = true,
        centered=true,
        enableConfirmBtn = true,
        enableSecondConfirmBtn = false,
        handleClickSaveButton,
        handleClickSecondSaveButton,
        saveTitle = 'Enregistrer',
        secondSaveTitle='',
        cssClass='',
        cssConfirmBtnClass='',
        cssSecondConfirmBtnClass='',
        handleClose,
        closeTitle = 'Annuler',
        show,
        title, children
    } = props;
    return (
        <Fragment>
            <Modal
                show={show}
                onHide={handleClose}
                className={cssClass}
                size={size}
                aria-labelledby="contained-modal-title-vcenter"
                centered={centered}
                backdrop="static"
                keyboard={false}>
                <div className={enabledLoading ? "divLoader loading": ""}>
                    {enabledLoading && <div className="divModalLoader"/>}
                    {useHeader &&
                    <Modal.Header>
                        <div>{title}</div>
                    </Modal.Header>}
                    <Modal.Body className={"app-modal"}>
                        {children}
                    </Modal.Body>
                    {useFooter &&
                    <Modal.Footer>
                        {textFooter != null && <div className="modal-footer-text"><ParseHtml value={textFooter}/></div>}
                        <Button variant="outline-secondary mr-3" onClick={handleClose}>
                            {closeTitle}
                        </Button>
                        {enableConfirmBtn && <Button className={cssConfirmBtnClass} variant="primary" onClick={handleClickSaveButton}>{saveTitle}</Button>}
                        {enableSecondConfirmBtn && <button className={cssSecondConfirmBtnClass}  onClick={handleClickSecondSaveButton}>{secondSaveTitle}</button>}
                    </Modal.Footer>}
                </div>
            </Modal>
        </Fragment>
    );
};
export default AppModalComponent;
