import React, {FunctionComponent, useEffect, useState} from "react";
import {DIALOG_TYPE, HTTP_RESPONSE} from "@utils/constants";
import {useFormik} from "formik";
import "../loader/loader.css";
import * as Yup from "yup";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  FormFeedback, Alert
} from "reactstrap"
import {DialogInterface} from "@app/core/types/app.state";
import PermissionRepository from "@repository/PermissionRepository";
import DialogBoxComponent from "../utilities/DialogBoxComponent";
import { withTranslation } from "react-i18next";
/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EditPermission: FunctionComponent<any> = (props: any): any => {
  const {id = 0, onEditSuccess = {}, apiUrl = ""} = props;
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState<DialogInterface>({
    show: false,
    onConfirm: () => {
    },
    handleClose: () => {
    },
    message: "",
    type: DIALOG_TYPE.ALERT
  });

  const manager = new PermissionRepository(apiUrl);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id : 0,
      name: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Veuillez entrer le nom")
    }),
    onSubmit: (values) => {
      setLoading(true)
      let tmp = values
      
      if(tmp.description){}
      else{
        tmp.description = ""
      }

      if(tmp.id){}
      else{
        delete tmp["id"]
      }

      manager.flush({...tmp ,}).then((response) => {
        setLoading(false);
        if (typeof onEditSuccess == 'function') {
          onEditSuccess(response);
        }
      }).catch(response => { 
        catchError(response)
      })
    }
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      manager.find(id).then((item) => {
        setLoading(false);
        validation.setValues(item);
      })
    } else {
      setLoading(false);
    }
  }, []);

  /**
   * Gestion de la soumission du formulaire
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    validation.handleSubmit();
  };

  const catchError = (response: any) => {
    const currentDialog: DialogInterface = {
      show: true,
      onConfirm: () => closeDialogModal(),
      handleClose: () => closeDialogModal(),
      type: DIALOG_TYPE.ALERT
    };
    switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        currentDialog.message = '';
        if (response.data.message) {
          currentDialog.message = response.data.message;
        }
        break;
      }
      default:
    }
    setDialog(currentDialog);
  };

  /**
   */
  const closeDialogModal = () => {
    setDialog({...dialog, show: false})
  };

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("EDIT_PERMISSION")}</CardTitle>
                <CardSubtitle className="mb-3">
                {props.t("PLEASE_FILL_PERMISSION_DETAIL")}
                </CardSubtitle>
                <Form onSubmit={(e) => handleSubmitForm(e)}>
                  <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="mb-3">
                            <Label className="form-label"> {props.t("NAME")} <span className="text-danger">*</span> </Label>
                            <Input
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder={props.t("NAME")}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            />
                            {validation.touched.name && validation.errors.name ? (
                            <FormFeedback
                                type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("DESCRIPTION_")} </Label>
                            <Input
                            id="description"
                            name="description"
                            className="form-control"
                            placeholder={props.t("DESCRIPTION_")}
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            />
                        </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary">
                      {props.t("SAVE_MODIFICATION")}    
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
      <DialogBoxComponent
        handleClose={() => closeDialogModal()}
        handleConfirm={() => dialog.onConfirm()}
        show={dialog.show}
        type={dialog.type}
      />
    </React.Fragment>
  )
};
export default withTranslation()(EditPermission);
