import React, {FunctionComponent, useEffect} from "react";
import {LoginProps} from "@app/core/types/generic.interface";
import "../loader/loader.css";
import axios from "axios";
import UserProvier from "@app/services/UserProvier";
/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LogoutUser: FunctionComponent<LoginProps> = () => {

    useEffect(() => {
        delete axios.defaults.headers['Authorization']
        delete axios.defaults.headers.common['Authorization']
      }, [])

  return (
    <React.Fragment></React.Fragment>
  )
};

export default LogoutUser;
