import React, {FunctionComponent, useEffect, useState} from "react";
import "../loader/loader.css";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input} from "reactstrap"
import PermissionRepository from "@repository/PermissionRepository";

/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ViewPermission: FunctionComponent<any> = (props: any): any => {
  const {apiUrl = "", id = 0} = props;
  const [loading, setLoading] = useState(true);
  const manager = new PermissionRepository(apiUrl);

  const[permission, setPermission] = useState(null)

  useEffect(() => {
    if (id) {
      setLoading(true);
      manager.find(id).then((item) => {
        setLoading(false);
        setPermission(item);
      })
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{"Détails d'une Permission"}</CardTitle>
                <div>
                  <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="mb-3">
                            <Label className="form-label">Nom </Label>
                            <Input
                            id="name"
                            name="name"
                            className="form-control"
                            type="text"
                            disabled={true}
                            value={permission ? permission.name : ""}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Description </Label>
                            <Input
                            id="description"
                            name="description"
                            className="form-control"
                            type="textarea"
                            disabled={true}
                            value={permission ? permission.description || "" : ""}
                            />
                        </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
    </React.Fragment>
  )
};
export default ViewPermission;
