import React, {FunctionComponent, useEffect, useState} from "react";
import { withTranslation } from "react-i18next";
import "../loader/loader.css";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input} from "reactstrap"
import ReferenceRepository from "@repository/ReferenceRepository";

/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ViewReference: FunctionComponent<any> = (props: any): any => {
  const {id = 0, apiUrl} = props;
  const [loading, setLoading] = useState(true);
  const manager = new ReferenceRepository(apiUrl);

  const[reference, setReference] = useState(null)


  useEffect(() => {
    if (id) {
      setLoading(true);
      manager.find(id).then((item) => {
        setLoading(false);
        setReference(item);
      })
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("REFERENCE_DETAILS")}</CardTitle>
                <div>
                  <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="mb-3">
                            <Label className="form-label">{props.t("PARENT")} </Label>
                            <Input
                            className="form-control"
                            type="text"
                            disabled={true}
                            value={ (reference && reference.parentReference) ? reference.parentReference.name : ""}
                            />
                        </div>

                        <div className="mb-3">
                            <Label className="form-label">{props.t("NAME")}</Label>
                            <Input
                            className="form-control"
                            type="text"
                            disabled={true}
                            value={reference ? reference.name : ""}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("CODE")}</Label>
                            <Input
                            className="form-control"
                            type="textarea"
                            disabled={true}
                            value={reference ? reference.code : ""}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("DESCRIPTION")} </Label>
                            <Input
                            className="form-control"
                            type="textarea"
                            disabled={true}
                            value={reference ? reference.description : ""}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("IS_ACTIVE")}</Label>
                            <Input
                              className="form-control"
                              type="checkbox"
                              disabled={true}
                              checked={reference?reference.isActive==1 : false}
                              onChange={()=>{}}
                            />
                        </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
    </React.Fragment>
  )
};

export default withTranslation()(ViewReference)