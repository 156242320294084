import React, {FunctionComponent, useEffect, useState} from "react";
import {DIALOG_TYPE, HTTP_RESPONSE} from "@utils/constants";
import {useFormik} from "formik";
import "../loader/loader.css";
import * as Yup from "yup";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input} from "reactstrap"
import {DialogInterface} from "@app/core/types/app.state";
import UserCompanyRepository from "@repository/UserCompanyRepository";
import DialogBoxComponent from "../utilities/DialogBoxComponent";
import AutocompleteSelectType from "../form/core/type/AutocompleteSelectType";
import RoleRepository from "@app/repository/RoleRepository";
import UserCompanyRoleRepository from "@app/repository/UserCompanyRoleRepository";
import { withTranslation } from "react-i18next";
/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EditUserCompanyRole: FunctionComponent<any> = (props: any): any => {
  const {id = 0, apiUrl = "", onEditSuccess = {}, apiRoleUrl, apiRolePermissionUrl, apiUserCompanyRoleUrl} = props;
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState<DialogInterface>({
    show: false,
    onConfirm: () => {
    },
    handleClose: () => {
    },
    message: "",
    type: DIALOG_TYPE.ALERT
  });

  const manager = new UserCompanyRepository(apiUrl);

  const userCompanyRoleRepository = new UserCompanyRoleRepository(apiUserCompanyRoleUrl);

  const roleRepo = new RoleRepository(apiRoleUrl,apiRolePermissionUrl);

  const [initialRoles, setInitialRoles] = useState([]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id : 0,
      companyId: '',
      userId: '',
      roles : [],
      user : null,
      company : null
    },
    validationSchema: Yup.object({
      //roles: Yup.object().required("Veuillez spécifier au moins un role")
    }),
    onSubmit: (values) => {
      setLoading(true)
      let tmp = values
    

      let added_role = values.roles.filter( (value: any, index: any, arr: any) => { 
        return initialRoles.indexOf(value) == -1;
      });

      let removed_role = initialRoles.filter( (value, index, arr) => { 
        return values.roles.indexOf(value) == -1;
      });

      removed_role.forEach(e =>{
        userCompanyRoleRepository.deleteRole(tmp.id, e.id);
      })

      added_role.forEach( (role : any) =>{
        userCompanyRoleRepository.flush({userCompanyId:tmp.id , roleId : role.id}).then( (response) => {  
        }).catch(response => {
          catchError(response)
        })
      })
      setLoading(false);
      onEditSuccess(null);
    }
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      manager.findIncludeAll(id).then((item) => {
        setLoading(false);
        validation.setValues(item.items[0]);
        setInitialRoles(item.items[0].roles ? item.items[0].roles : []);
      }).catch((error)=>{
        catchError(error);
      })
    } else {
      setLoading(false);
    }
  }, []);

  /**
   * Gestion de la soumission du formulaire
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    validation.handleSubmit();
  };

  const catchError = (response: any) => {
    const currentDialog: DialogInterface = {
      show: true,
      onConfirm: () => closeDialogModal(),
      handleClose: () => closeDialogModal(),
      type: DIALOG_TYPE.ALERT
    };
    switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        currentDialog.message = '';
        if (response.data.message) {
          currentDialog.message = response.data.message;
        }
        break;
      }
      default:
    }
    setDialog(currentDialog);
  };

  /**
   */
  const closeDialogModal = () => {
    setDialog({...dialog, show: false})
  };

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("EDIT_USER_COMPANY")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t("EDIT_USER_COMPANY_DETAIL")}
                </CardSubtitle>
                <Form onSubmit={(e) => handleSubmitForm(e)}>
                  <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="mb-3">
                            <Label className="form-label">{props.t("COMPANY")} <span className="text-danger">*</span> </Label>
                            <Input
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder={props.t("ENTER_NAME")}
                            type="text"
                            disabled={true}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.company ? validation.values.company.name : ""}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("USERS")} </Label>
                            <Input
                            id="description"
                            name="description"
                            className="form-control"
                            placeholder={props.t("ENTER_DESCRIPTION")}
                            type="textarea"
                            disabled={true}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.user ? validation.values.user.username : ""}
                            />
                        </div>
                        <div className="mb-3">
                          <Label
                              className="form-label">{props.t("ROLE")}</Label>
                          <AutocompleteSelectType
                              id={"roles"}
                              name={"roles"}
                              multiple={true}
                              choiceAttr={"name"}
                              value={validation.values.roles || []}
                              remoteChoices={(inputValue:any) => {return new Promise(resolve => resolve(roleRepo.findAll( 1,10, inputValue).then((data) => data.items)))}}
                              placeholder={props.t("ROLE_PLACEHOLDER")}
                              onChange={(e:any) => validation.handleChange(e)}
                              defaultCss={""}
                            />
                        </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary">
                      {props.t("SAVE_MODIFICATIONS")}
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
      <DialogBoxComponent
        handleClose={() => closeDialogModal()}
        handleConfirm={() => dialog.onConfirm()}
        show={dialog.show}
        type={dialog.type}
      />
    </React.Fragment>
  )
};
export default  withTranslation()(EditUserCompanyRole);