import React, {FunctionComponent, useEffect, useState} from "react";
import "../loader/loader.css";
import { withTranslation } from "react-i18next";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input} from "reactstrap"
import ReferenceLabelRepository from "@app/repository/ReferenceLabelRepository";

/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ViewReferenceLabel: FunctionComponent<any> = (props: any): any => {
  const {referenceLabelId = 0, apiUrl} = props;
  const [loading, setLoading] = useState(true);
  const manager = new ReferenceLabelRepository(apiUrl);

  const[referenceLabel, setReferenceLabel] = useState(null)


  useEffect(() => {
    if (referenceLabelId) {
      setLoading(true);
      manager.findIncludeAll(referenceLabelId).then((item) => {
        setReferenceLabel(item);
        setLoading(false);
      })
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{"Détails d'un label de référence"}</CardTitle>
                <div>
                  <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="mb-3">
                            <Label className="form-label"> {props.t("LABEL")}</Label>
                            <Input
                            className="form-control"
                            type="text"
                            disabled={true}
                            value={referenceLabel ? referenceLabel.label : ""}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("ASSOCIATED_REFERENCE_VALUE")}</Label>
                            <Input
                              className="form-control"
                              type="text"
                              disabled={true}
                              value={ ( referenceLabel && referenceLabel.referenceValue) ? referenceLabel.referenceValue.code : ""}
                              />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("ASSOCIATED_LANGUE")} </Label>
                            <Input
                            className="form-control"
                            type="text"
                            disabled={true}
                            value={ (referenceLabel && referenceLabel.langue) ? referenceLabel.langue.code : ""}
                            />
                        </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
    </React.Fragment>
  )
};
export default withTranslation()(ViewReferenceLabel)
