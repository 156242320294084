import React, {FunctionComponent, useEffect, useState} from "react";
import "../loader/loader.css";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input} from "reactstrap"
import CompanyRepository from "@repository/CompanyRepository";
import ActionsFormatter from "../utilities/ActionsFormatter";
import { withTranslation } from "react-i18next";

/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ViewCompany: FunctionComponent<any> = (props: any): any => {
  const {id = 0, apiUrl, apiUserCompanyUrl, userPath, Link, showDeleteAssociatedUser, showEditAssociatedUser, showViewAssociatedUser} = props;
  const [loading, setLoading] = useState(true);
  const manager = new CompanyRepository(apiUrl,apiUserCompanyUrl);

  const[showPermissionList, setShowPermissionList] = useState(false);
  const[company, setCompany] = useState(null)

  const togglePermissionList = (e:any)=>{
    setShowPermissionList(!showPermissionList);
  } 

  useEffect(() => {
    if (id) {
      setLoading(true);
      manager.findIncludeAll(id).then((item) => {
        setLoading(false);
        setCompany(item.items[0]);
      })
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{"Détails d'une Compagnie"}</CardTitle>
                <div>
                  <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="mb-3">
                            <Label className="form-label">{props.t("NAME")} </Label>
                            <Input
                            id="name"
                            name="name"
                            className="form-control"
                            type="text"
                            disabled={true}
                            value={company ? company.name : ""}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("DESCRIPTION")}</Label>
                            <Input
                            id="description"
                            name="description"
                            className="form-control"
                            type="textarea"
                            disabled={true}
                            value={company ? company.description : ""}
                            />
                        </div>

                        <div className="mb-3 card" id="accordion">
                          <div className="card-header">
                              <h5 className="mb-0">
                                  <a className="btn btn-link" onClick={ (e) => togglePermissionList(e)}  href="#">
                                      {props.t("USERS_CIE_")}
                                  </a>
                              </h5>
                          </div>
                          { showPermissionList ? showViewAssociatedUser ?(
                              <div className="collapse show" data-parent="#accordion">
                                  <div className="card-body">
                                    {
                                        company.users && company.users.length > 0 ?
                                            <div className="table-responsive">
                                                <div className="react-bootstrap-table">
                                                    <table
                                                        className="table table align-middle table-nowrap">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th tabIndex={0}>{props.t("USERNAME")}</th>
                                                                <th tabIndex={0}>{props.t("EMAIL")}</th>
                                                                <th tabIndex={0}>{props.t("STATUS")}</th>
                                                                <th tabIndex={0}/>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {company.users.length > 0 && company.users.map((item:any, index:number) => <tr key={index}>
                                                            <td>{item.username}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.enabled == 1 ? <span className="badge bg-primary p-2">{props.t("ACTIVE")}</span> :
                                                              <span className="badge bg-danger p-2">{props.t("INACTIVE")}</span>}</td>
                                                            <td></td>
                                                            <td>
                                                                <ActionsFormatter
                                                                    item={item}
                                                                    onDelete={(event:any, elt:any) => {}}
                                                                    path = {userPath}
                                                                    Link = {Link}
                                                                    showEdit = {showEditAssociatedUser}
                                                                    showView = {showViewAssociatedUser}
                                                                    showDelete = {showDeleteAssociatedUser}
                                                                  />
                                                            </td>
                                                        </tr>)}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        :
                                          props.t("NO_CIE_USERS")
                                      }
                                  </div>
                              </div>
                            ) : 
                              <div> {props.t("USERS_LIST_NO_AUTH")}</div>
                            :  ""
                          }
                        </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
    </React.Fragment>
  )
};
export default withTranslation()(ViewCompany);

