import React, {Fragment, FunctionComponent} from 'react'
import {LocaleInterface} from "../../utils/constants";
import AppModalComponent from "../AppModalComponent";
import {DIALOG_TYPE} from "@utils/constants";

interface DialogBoxComponentPropsInterface {
    locale?: LocaleInterface, //Langue d'affichage
    title?: string, //Titre de la modale
    handleClose: any, //Fonction appelé quand la modal est fermée
    handleConfirm: any, //Fonction appelé quand on clique sur le bouton de confirmation
    children?: any, // Contenu de la modal
    show: any, //Permet de savoir si la modale est ouverte ou pas
    type: number, //Permet de savoir le type de dialog,
    message?: string
}

const ConfirmContent = ({bulk = false}: any) => <Fragment>
    <div className="icon-box">
        <i className="ik ik-x-circle"/>
    </div>
    <h4 className="w-100">Êtes-vous sûr?</h4>
    {bulk ? <p>Voulez-vous vraiment supprimer ces enregistrements? Ce processus ne peut être annulé.</p> :
        <p>Voulez-vous vraiment supprimer cet enregistrement? Ce processus ne peut être annulé.</p>}
</Fragment>;

const SessionExpiredContent = () => <Fragment>
    <div className="icon-box">
        <i className="ik ik-shield-off"/>
    </div>
    <h4 className="w-100">Votre session a expiré</h4>
    <p>Vous n'êtes pas authorisé à accéder à la ressource demandée. Pour des mesures de sécurité, nous vous avons
        déconnecté. Veuillez vous reconnecter.</p>
</Fragment>;

const ServerErrorContent = ({message = ''}) => <Fragment>
    <div className="icon-box">
        <i className="ik ik-alert-circle"/>
    </div>
    <h4 className="w-100">Une erreur s'est produite</h4>
    <p>Nous avons détecté une erreur lors de l'exécution de votre requête.</p>
    {message.length ? <code>{message}</code>: ""}
</Fragment>;

const AlertContent = () => <Fragment>
    <div className="icon-box">
        <i className="ik ik-alert-triangle"/>
    </div>
    <h4 className="w-100">Ressource introuvable</h4>
    <p>La ressource demandée est introuvable. Elle semble avoir été supprimée ou déplacée.</p>
</Fragment>;

const SuccessContent = ({message =""}) => <Fragment>
    <div className="icon-box">
        <i className="ik ik-check" style={{color: '#0070c0'}}/>
    </div>
    <h4 className="w-100">Envoi terminé</h4>
    <p>Votre demande à été envoyé avec succes, connecté vous a votre espace d'administration pour suivre son evolution</p>
    {message.length && <code style={{color: '#0070c0'}}>{message}</code>}
</Fragment>;

/**
 *
 * Affiche une modale de confirmation d'action.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const DialogBoxComponent: FunctionComponent<DialogBoxComponentPropsInterface> = (props: DialogBoxComponentPropsInterface) => {
    const {handleClose = {}, handleConfirm, show, children, title = '', message="", type} = props;
    const dialog: any = {};
    let cssClass = '';
    let confirmText = '';
    let boxTitle = '';
    let content: any = '';
    let closeTitle: string = 'Annuler';
    let showConfirmButton: boolean = true;
    switch (type) {
        case DIALOG_TYPE.CONFIRM: {
            cssClass += 'confirm-dialog';
            confirmText = 'Confirmer';
            break;
        }
        case DIALOG_TYPE.CONFIRM_DELETION: {
            cssClass += 'confirm-dialog';
            confirmText = 'Confirmer la suppression';
            boxTitle = 'Opération de suppression';
            content = <ConfirmContent/>;
            break;
        }
        case DIALOG_TYPE.CONFIRM_BULK_DELETION: {
            cssClass += 'confirm-dialog';
            confirmText = 'Confirmer la suppression';
            boxTitle = 'Opération de suppression';
            content = <ConfirmContent bulk={true}/>;
            break;
        }
        case DIALOG_TYPE.ALERT: {
            cssClass += 'alert-dialog';
            showConfirmButton = false;
            closeTitle = 'Fermer et retourner';
            content = <AlertContent/>;
            break;
        }
        case DIALOG_TYPE.SESSION_EXPIRED: {
            cssClass += 'alert-session-expired';
            confirmText = 'Me reconnecter';
            content = <SessionExpiredContent/>;
            break;
        }
        case DIALOG_TYPE.SERVER_ERROR: {
            cssClass += 'alert-session-expired';
            confirmText = 'Me reconnecter';
            content = <ServerErrorContent message={message}/>;
            showConfirmButton = false;
            closeTitle = 'Fermer';
            break;
        }
        case DIALOG_TYPE.SUCCESS: {
            cssClass += 'success-dialog';
            content = <SuccessContent message={message}/>;
            showConfirmButton = false;
            closeTitle = 'Fermer';
            break;
        }
        default:
    }
    const closeDialog = () => {
        if (handleClose) {
            handleClose(); //Appel du callback lorsque le dialog est fermé.
        }
    };
    const confirmDialog = (e: any) => {
        if (type == DIALOG_TYPE.SESSION_EXPIRED) {
            window.location.replace("/login");
        } else {
            if (handleConfirm) {
                handleConfirm(e); //Appel du callback lorsque le bouton confirm est cliqué
            }
        }
    };
    return (
        <Fragment>
            {type != DIALOG_TYPE.AUTH_REQUIRED &&
            <AppModalComponent
                enabledLoading={false}
                handleClose={closeDialog}
                show={show}
                cssClass={'dialog ' + cssClass}
                cssConfirmBtnClass={cssClass}
                saveTitle={confirmText}
                closeTitle={closeTitle}
                enableConfirmBtn={showConfirmButton}
                handleClickSaveButton={(e: any) => confirmDialog(e)}
                title={boxTitle}>
                <div className="row no-gutters pt-4">
                    <div className="col-12">
                        <div className="main-form-card pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="content-form-item">
                                        <div className="tab-content">
                                            <div id="leaner-identity" className="tab-pane active">
                                                <div className="mx-auto text-center box-content">
                                                    {content}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AppModalComponent>}
        </Fragment>
    )
};

export default DialogBoxComponent;
