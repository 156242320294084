import * as React from 'react'
import CopyrightValue from "@components/skin/CopyrightValue";
import {ParameterProps} from "@app/core/types/generic.interface";
import LogoValue from "@components/skin/LogoValue";

export const CopyrightComponent = (props: ParameterProps) => {
  return <div>
    <CopyrightValue {...props}/>
  </div>
}

export const LogoComponent = (props: ParameterProps) => {
  return <div>
    <LogoValue {...props}/>
  </div>
}
