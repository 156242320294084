import React from 'react'

interface ParseHtmlProps {
    value: any,
    style?: any
}
const ParseHtml = (props: ParseHtmlProps) => {
    const {value, style={}} = props;
    return (
        <React.Fragment>
            <div dangerouslySetInnerHTML={{__html: value}} style={style} />
        </React.Fragment>
    );
};

export default ParseHtml
