import React, {FunctionComponent, useEffect, useState} from "react";
import "../loader/loader.css";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input} from "reactstrap"
import RoleRepository from "@repository/RoleRepository";
import ActionsFormatter from "../utilities/ActionsFormatter";
import { withTranslation } from "react-i18next";
/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ViewRole: FunctionComponent<any> = (props: any): any => {
  const {apiUrl = "", apiRolePermissionUrl="", id = 0} = props;
  const [loading, setLoading] = useState(true);
  const manager = new RoleRepository(apiUrl, apiRolePermissionUrl);

  const[showPermissionList, setShowPermissionList] = useState(false);
  const[role, setRole] = useState(null)

  const togglePermissionList = (e:any)=>{
    setShowPermissionList(!showPermissionList);
  } 

  useEffect(() => {
    if (id) {
      setLoading(true);
      manager.findIncludeAll(id).then((item) => {
        setLoading(false);
        setRole(item.items[0]);
      })
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("ROLE_DETAIL")}</CardTitle>
                <div>
                  <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="mb-3">
                            <Label className="form-label">{props.t("NAME")} </Label>
                            <Input
                            id="name"
                            name="name"
                            className="form-control"
                            type="text"
                            disabled={true}
                            value={role ? role.name : ""}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("DESCRIPTION_")} </Label>
                            <Input
                            id="description"
                            name="description"
                            className="form-control"
                            type="textarea"
                            disabled={true}
                            value={role ? role.description || "" : ""}
                            />
                        </div>

                        <div className="mb-3 card" id="accordion">
                          <div className="card-header">
                              <h5 className="mb-0">
                                  <a className="btn btn-link" onClick={ (e) => togglePermissionList(e)}  href="#">
                                      {props.t("ASSOCIATED_PERMISSIONS")}
                                  </a>
                              </h5>
                          </div>
                          { showPermissionList ? (
                              <div className="collapse show" data-parent="#accordion">
                                  <div className="card-body">
                                    {
                                        role.permissions && role.permissions.length > 0 ?
                                            <div className="table-responsive">
                                                <div className="react-bootstrap-table">
                                                    <table
                                                        className="table table align-middle table-nowrap">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th tabIndex={0}>{props.t("NAME")}</th>
                                                                <th tabIndex={0}>{props.t("DESCRIPTION_")}</th>
                                                                <th tabIndex={0}/>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {role.permissions.length > 0 && role.permissions.map((item:any, index:number) => <tr key={index}>
                                                            <td>{item.name}</td>
                                                            <td>{item.description}</td>
                                                            <td>
                                                                <ActionsFormatter
                                                                    item={item}
                                                                    onDelete={props.onDelete}
                                                                    path = {props.permissionPath}
                                                                    showEdit = {props.showAssociatedPermissionsEdit}
                                                                    showView = {props.showAssociatedPermissionsView}
                                                                    Link = {props.Link}
                                                                    showDelete = {props.showAssociatedPermissionsDelete}
                                                                />
                                                            </td>
                                                        </tr>)}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        :
                                         props.t("NO_PERMISSIONS")
                                      }
                                  </div>
                              </div>
                            ) : 
                              ""
                          }
                        </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
    </React.Fragment>
  )
};
export default withTranslation()(ViewRole)
