/**
 * Helper pour manager les champs select autocomplete des formulaires.
 * Dans une mésure du possible, ce helper peut être modularisé si les méthode prennent du nombre.
 */

import ReferenceCompanyRepository from "@app/repository/ReferenceCompanyRepository";
import ReferenceLangueRepository from "@app/repository/ReferenceLangueRepository";
import ReferenceRepository from "@app/repository/ReferenceRepository";
import ReferenceValueRepository from "@app/repository/ReferenceValueRepository";

const Autocomplete = {

   /**
     * Autocomplete sur les références
     * @param inputValue
     * @param api
     */
    references: (api: string,inputValue: any) => {
        const em = new ReferenceRepository(api);
        return new Promise(resolve => resolve(em.findAll(1, 10, inputValue).then((data) => data.items)))
    },

   /**
     * Autocomplete sur les compagnies
     * @param inputValue
     * @param api
     */
    referenceCompanies: (api: string,inputValue: any) => {
        const em = new ReferenceCompanyRepository(api);
        return new Promise(resolve => resolve(em.findAll(1, 10, inputValue).then((data) => data.items)))
    },

    /**
     * Autocomplete sur les valeurs de référence
     * @param inputValue
     * @param api
    */
    referenceValues: (api: string,inputValue: any) => {
        const em = new ReferenceValueRepository(api);
        return new Promise(resolve => resolve(em.findAll(1, 10, inputValue).then((data) => data.items)))
    },

    /**
     * Autocomplete sur les valeurs de référence en fonction de la reference et la compagnie associée
     * @param inputValue
     * @param api
    */
    referenceValuesByReferenceAndCompany: (api: string,inputValue: any, referenceId:any, companyId:any,differentOf:any) => {
        const em = new ReferenceValueRepository(api);
        return new Promise(resolve => resolve(em.findAllByReferenceAndCompany(inputValue, referenceId, companyId,differentOf).then((data) => data.items)))
    },

    /**
     * Autocomplete sur les langues
     * @param inputValue
     * @param api
    */
     referenceLangues: (api: string,inputValue: any) => {
        const em = new ReferenceLangueRepository(api);
        return new Promise(resolve => resolve(em.findAll(1, 10, inputValue).then((data) => data.items)))
    },

    filtreReferences : (em:ReferenceRepository, active:boolean, code_reference: any, code_company: any, code_langue: any, statut='all', reference_value_id:any, langue_id:any ,search = '') =>{
        return new Promise(resolve => resolve(em.filtre(active, code_reference, code_company, code_langue, statut, reference_value_id, langue_id, search).then((data) => data)))
    }
};

export default Autocomplete;