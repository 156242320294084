import React, {FunctionComponent, useEffect, useState} from "react";
import { withTranslation } from "react-i18next";
import {DIALOG_TYPE, HTTP_RESPONSE} from "@utils/constants";
import {useFormik} from "formik";
import "../loader/loader.css";
import * as Yup from "yup";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  FormFeedback, Alert
} from "reactstrap"
import ReferenceRepository from "@repository/ReferenceRepository";
import DialogBoxComponent from "../utilities/DialogBoxComponent";
import AutocompleteSelectType from "@app/helpers/Form/AutocompleteSelectType";
import Autocomplete from "@app/helpers/select.autocomplete";

/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EditReference: FunctionComponent<any> = (props: any): any => {
  const {id = 0, onEditSuccess = {}, apiUrl = ""} = props;
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState({
    show: false,
    onConfirm: () => {
    },
    handleClose: () => {
    },
    message: "",
    type: DIALOG_TYPE.ALERT
  });
  const [isParent, setIsParent] = useState(false);

  const manager = new ReferenceRepository(apiUrl);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    initialValues: {
      name: '',
      description: '',
      code : '',
      isActive: false,
      parent: null
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("NAME_REQUIRED")),
      code: Yup.string().required(props.t("CODE_REQUIRED"))
    }),
    onSubmit: (values) => {
      setLoading(true)
      let tmp = JSON.parse(JSON.stringify(values))
      
      if( tmp.parent){
        tmp.parent = tmp.parent.id
      }

      delete tmp.parentReference

      if(tmp.description){
      }else{
        tmp.description = ""
      }

      tmp.isActive = tmp.isActive ? 1 : 0

      manager.flush({...tmp }).then(async (response) => { 
        setLoading(false);
        if (typeof onEditSuccess == 'function') {
          onEditSuccess(response);
        }
      }).catch(response => {
        catchError(response)
      })
  
    }
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      manager.find(id).then((item) => {
        console.log(item);
        setLoading(false);
        validation.setValues({...item, parent: item.parent? item.parentReference : null});
      }).catch((error)=>{
        catchError(error);
      })
    } else {
      setLoading(false);
    }
  }, []);

  //function to know if the reference is a parent or not
  useEffect(() => {
    if (id) {
      setLoading(true);
      manager.isParent(id).then((item) => {
        setIsParent(item);
        setLoading(false);
      }).catch((error)=>{
        catchError(error);
      })
    } else {
      setLoading(false);
    }
  },[]);

  /**
   * Gestion de la soumission du formulaire
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    validation.handleSubmit();
  };

  const catchError = (response: any) => {
    let currentDialog = {
      show: true,
      onConfirm: () => closeDialogModal(),
      handleClose: () => closeDialogModal(),
      type: DIALOG_TYPE.ALERT,
      message: ""
    };

    switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        currentDialog.message = '';
        if (response.data.message) {
          currentDialog.message = response.data.message;
        }
        break;
      }
      case HTTP_RESPONSE.HTTP_UNPROCESSABLE_ENTITY: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        if(response.data){
          if( response.data.error.code=="ER_DUP_ENTRY"){
              currentDialog.message = "Une référence avec le même code/nom existe déjà";
          }else{
              currentDialog.message = "Veuillez vérifiez les champs du formulaire";
          }
        }else{
            currentDialog.message = response.message
        }
        break;
      }
      default:
        break;
    }
    setDialog(currentDialog);
  };

  /**
   */
  const closeDialogModal = () => {
    setDialog({...dialog, show: false})
  };

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("UPDATE_REFERENCE")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t("UPDATE_REFERENCE_DESCRIPTION")}
                </CardSubtitle>
                <Form onSubmit={(e) => handleSubmitForm(e)}>
                  <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="mb-3">
                            <Label className="form-label">
                              {props.t("PARENT")}
                            </Label>
                            <AutocompleteSelectType
                                id={"parent"}
                                name={"parent"}
                                multiple={false}
                                className="input-form"
                                choiceAttr="name"
                                value={validation.values.parent || ""}
                                remoteChoices={(inputValue:any) => Autocomplete.references(apiUrl, inputValue)}
                                placeholder={props.t("SELECT_PARENT")}
                                onChange={(e:any) => validation.handleChange(e)}
                                defaultCss={""}
                                isDisabled={id!=0}
                                error={
                                  validation.touched.parent && validation.errors.parent ? props.t("REQUIRED") : false
                                }
                              />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("NAME")}<span className="text-danger">*</span> </Label>
                            <Input
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder={props.t("ENTER_NAME")}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            disabled={id!=0}
                            invalid={
                              validation.touched.code && validation.errors.code ? true : false
                            }
                            />
                            {validation.touched.name && validation.errors.name ? (
                            <FormFeedback
                                type="invalid" className="invalid-feedback d-block">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("CODE")} <span className="text-danger">*</span> </Label>
                            <Input
                            id="code"
                            name="code"
                            className="form-control"
                            placeholder={props.t("ENTER_CODE")}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.code || ""}
                            disabled={id!=0}
                            invalid={
                              validation.touched.code && validation.errors.code ? true : false
                            }
                            />
                            {validation.touched.code && validation.errors.code ? (
                            <FormFeedback
                                type="invalid" className="invalid-feedback d-block">{validation.errors.code}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("DESCRIPTION")} </Label>
                            <Input
                            id="description"
                            name="description"
                            className="form-control"
                            placeholder={props.t("ENTER_DESCRIPTION")}
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            />
                        </div>
                        <div className="d-flex gap-3 mb-3">
                          <Label className="form-label">{props.t("IS_ACTIVE")}</Label>
                          <Input
                              id="isActive"
                              value="true"
                              name="isActive"
                              className="form-control"
                              type="checkbox"
                              checked={validation.values.isActive}
                              onChange={()=>{}}
                              onClick={(e)=>{
                                      let tmp = validation.values
                                      validation.setValues({...tmp, isActive:!tmp.isActive})
                                  }
                                }
                              disabled={(validation.values.parent || isParent) ? true : false}
                          />
                        </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button type="submit" className="btn btn-primary">
                      {props.t("SAVE_MODIFICATIONS")}
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
      <DialogBoxComponent
        handleClose={() => closeDialogModal()}
        handleConfirm={() => dialog.onConfirm()}
        show={dialog.show}
        type={dialog.type}
        message={dialog.message}
      />
    </React.Fragment>
  )
};

export default withTranslation()(EditReference)