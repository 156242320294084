import React, {Fragment} from "react";
import './loader.css';
/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LoadingContainer = (props:any) => {
    // eslint-disable-next-line react/prop-types
    const {loading = false,className = "",children,style={}} = props;
    return (
        <Fragment>
            <div className={(loading && " divLoader loading")+" "+className} style={style}>
                {loading && <div className="divModalLoader"/>}
                {children}
            </div>
        </Fragment>
    );
};
export default LoadingContainer;
