import UserProvier from "@app/services/UserProvier";
import { HTTP_RESPONSE } from "@app/utils/constants";
import axios from "axios";

export default class UserCompanyRoleRepository {
    protected api: string
  
    public constructor(api: string) {
      this.api = api
      UserProvier.setToken();
    }
  
    queries = (itemPerPage = 15, page = 1, search = '', querying = '') => {
      return (
        this.api +'?filter[limit]=' + itemPerPage +
        '&filter[skip]=' + ((page - 1) * itemPerPage > 0 ? (page - 1) * itemPerPage : 0)+
        '&filter[include][]=userCompany'+
        '&filter[include][]=role'
      )
    }
  
    queriesIncludeAll = (id:string) => {
      return (
        this.api +'?filter[where][id]=' + id +
        '&filter[include][]=userCompany'+
        '&filter[include][]=role'
      )
    }
  
    queriesByUserCompanyIncludeAll = (id:number) => {
      return (
        this.api +'?filter[where][userCompanyId]=' + id +
        '&filter[include][]=userCompany'+
        '&filter[include][]=role'
      )
    }

    add = (item: any) => {
      return axios
        .post(this.api, item)
        .then((response: { data: any }) => {
          return response.data
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    edit = (item: any) => {
      return axios
        .put(this.api + '/' + item.id, item)
        .then((response) => {
          return response.data
        })
        .catch((e) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    findAll = (page = 1, itemPerpage = 10, search = '') => {
      return axios
        .get(this.queries(itemPerpage, page, search))
        .then((response: { data: any }) => {
          return response.data
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }

    findByUserCompany = (id:number) => {
      return axios
        .get(this.queriesByUserCompanyIncludeAll(id))
        .then((response: { data: any }) => {
          return response.data
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    flush = (item:  any ) => {
      return axios
        .post(this.api + '', item)
        .then((response: { data: any }) => {
          return response.data
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    find = (id: string) => {
      return axios
        .get(this.api + '/' + id)
        .then((response: { data: any }) => {
          return response.data
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    findIncludeAll = (id: string) => {
      return axios
        .get(this.queriesIncludeAll(id))
        .then((response: { data: any }) => {
          return response.data
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    remove = (id: string) => {
      return axios
        .delete(this.api + '/' + id)
        .then((response: any) => {
          return id
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }

    deleteRole = ( userCompanyId: any, roleId: any) => {
      return axios.delete(this.api + '/' + userCompanyId +'/' + roleId)
      .then((response : any) => {
  
      })
      .catch((e: { response: any }) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
    }
}