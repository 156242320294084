import axios from 'axios'
import AbstractFoundation from '@entity/AbstractFoundation'
import CollectionInterface from '@app/core/types/collection.interface'
import AbstractFoundationI18n from '@entity/AbstractFoundationI18n'
import { HTTP_RESPONSE } from '@utils/constants'
import UserProvier from '@app/services/UserProvier'
import Access from "@entity/user/Access";

/**
 * Class AbstractRepository.
 * Repository sur les entities simples.
 *
 * @link       http://github.com/bssoufo
 * @author     Bruno Soufo <bssoufo@gmail.com>
 */
export default abstract class AbstractRepository<T extends AbstractFoundation> {
  protected api: string

  /**
   * Enable to fecth entity in front part or back part of Application
   * @param api
   */
  public constructor(api: string) {
    this.api = api

    UserProvier.setToken();
  }

  /**
   * Permet de retourner une instance hydratée de l'entité managée.
   * @param data
   */
  public abstract parse(data: any): T

  /**
   *
   * Permet de construire la requete qui pars sur le serveur.
   *
   * @param itemPerPage
   * @param page
   * @param search
   * @param fromDate
   * @param toDate
   * @param querying
   */
  protected queries(
    itemPerPage = 15,
    page = 1,
    search = '',
    fromDate = '',
    toDate = '',
    querying: string = ''
  ) {
    
    return (
      this.api +'?' +querying +'&filter[limit]=' +itemPerPage +'&filter[skip]=' +((page - 1) * itemPerPage > 0 ? (page - 1) * itemPerPage : 0)
    )
  }

  /**
   * Construit les paramètres de la requête.
   * @param args
   */
  protected queriesArg(args: any): string {
    return args.map((a: any) => {
      return Object.keys(a)
        .map(function (key) {
          return 'filter[where][' + key + ']' + '=' + encodeURIComponent(a[key])
        })
        .join('&')
    })
  }

  /**
   * Permet l'hydratation des champs multilingue
   *
   * @param entity entité de base
   * @param data donnée issue de l'api
   * @param i18nType classe mutlilangue
   * @param args contient le reste des champs multilangues
   * @return T
   */
  protected fieldsWalker(
    entity: T,
    data: any,
    i18nType?: new () => AbstractFoundationI18n,
    ...args: any
  ): T {
    entity.id = data.id

    return entity
  }

  /**
   * Retourne une collection d'entités.
   *
   * @param itemPerPage
   * @param page
   * @param search
   * @param fromDate
   * @param toDate
   * @param args une liste quelconque de parametres
   * @returns Promise<CollectionInterface>
   */
  public retrieve(
    itemPerPage = 15,
    page = 1,
    search = '',
    fromDate = '',
    toDate = '',
    ...args: any
  ): Promise<CollectionInterface> {
    console.log('args', args)
    console.log("search", search)
    return axios
      .get(
        this.queries(
          itemPerPage,
          page,
          search,
          fromDate,
          toDate,
          this.queriesArg(args)
        )
      )
      .then((response) => {
        return this.parseResponse(response)
      })
      .catch((e: any) => {
        if (e.response) {
          throw e.response
        } else {
          // eslint-disable-next-line no-throw-literal
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  /**
   *
   * Permet de parser le résultat d'une réponse
   *
   * @param response
   */
  protected parseResponse(response: any): CollectionInterface {
    const draftItems = response.data.items
    const totalItems = response.data.totalCount
    const items: Array<T> = draftItems.map((item: any) => this.parse(item))
    return { items: items, total: totalItems }
  }

  /**
   * Trouve une entrée en fontion de son ID.
   *
   * @param id
   * @returns Promise<T>
   */
  public find(id: number): Promise<T> {
    return axios
      .get(this.api + '/' + id)
      .then((response) => {
        return this.parse(response.data)
      })
      .catch((e: any) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  /**
   * Savegarde des changements sur une entité.
   *
   * @param item
   * @return Promise<T>
   */
  public flush(item: T): Promise<T> {
    if (item.id > 0) {
      return this.update(item.id, this.clear(item))
    } else {
      return this.add(this.clear(item))
    }
  }

  /**
   * Ajout d'une entrée
   *
   * @param item
   * @return Promise<T>
   */
  public add(item: T): Promise<T> {
    return axios
      .post(this.api + '/', this.clear(item))
      .then((response) => {
        return this.parse(response.data)
      })
      .catch((e: any) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  /**
   * Mise à jour d'une entrée
   *
   * @param id
   * @param item
   * @returns Promise<T>
   */
  public update(id: number, item: T): Promise<T> {
    return axios
      .put(this.api + '/' + id, this.clear(item))
      .then((response: any) => {
        return this.parse(response.data)
      })
      .catch((e: any) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  /**
   * Suppression d'une entrée
   *
   * @param id
   * @returns Promise<T>
   */
  public remove(id: number): Promise<T> {
    return axios
      .delete(this.api + '/' + id)
      .then((response: any) => {
        return this.parse(response.data)
      })
      .catch((e: any) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  /**
   *
   * @param items
   * @return Promise<boolean>
   */
  public bulkRemove(items: Array<number>): Promise<boolean> {
    return axios
      .post(this.api + '/bulk-delete', items)
      .then((response) => {
        return true
      })
      .catch((e: any) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  protected clear(item: T, isAdd: boolean = true): T {
    return item
  }

  /**
   * Permet d'upload une pièce jointe
   *
   * @param item
   * @param file
   * @return Promise<File>
   */
  public uploadAttachment(item: T, file: any): Promise<T> {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return axios
      .post(this.api + '/' + item.id + '/thumbnail', file, config)
      .then((response) => {
        return this.parse(response.data)
      })
      .catch((e: any) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }
}
