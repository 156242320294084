import React, {FunctionComponent, useEffect, useState} from "react";
import {DIALOG_TYPE, HTTP_RESPONSE} from "@utils/constants";
import {useFormik} from "formik";
import "../loader/loader.css";
import * as Yup from "yup";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  FormFeedback, Alert
} from "reactstrap"
import {DialogInterface} from "@app/core/types/app.state";
import CompanyRepository from "@repository/CompanyRepository";
import DialogBoxComponent from "../utilities/DialogBoxComponent";
import AutocompleteSelectType from "../form/core/type/AutocompleteSelectType";
import UserRepository from "@repository/user/UserRepository";
import { withTranslation } from "react-i18next";

/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EditCompany: FunctionComponent<any> = (props: any): any => {
  const {id = 0, onEditSuccess = {}, apiUrl = "", apiUserUrl, apiUserCompanyUrl} = props;
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState<DialogInterface>({
    show: false,
    onConfirm: () => {
    },
    handleClose: () => {
    },
    message: "",
    type: DIALOG_TYPE.ALERT
  });

  const manager = new CompanyRepository(apiUrl,apiUserCompanyUrl);

  const [initialUser, setInitialUser] = useState([]);

  const userRepo = new UserRepository(apiUserUrl);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id : '',
      name: '',
      description: '',
      users : []
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("NAME_REQUIRED"))
    }),
    onSubmit: (values) => {
      setLoading(true)
      let tmp = values
      
      if(tmp.description){
      }else{
        tmp.description = ""
      }

      let added_user = values.users.filter( (value, index, arr) => { 
        return initialUser.indexOf(value) == -1;
      });

      let removed_user = initialUser.filter( (value, index, arr) => { 
        return values.users.indexOf(value) == -1;
      });

      removed_user.forEach(e =>{
        manager.deleteUser(values.id, e.id);
      })
      
      delete tmp["users"]

      if(tmp.id && tmp.id!=""){

      }else{
        delete tmp["id"]
      }

      manager.flush({...tmp }).then(async (response) => {
        try {
          let responses = await Promise.all(added_user.map(e =>{
            let tmpId: number = parseInt( response ? response.id : id );
            return manager.addUser({companyId : tmpId, userId: e.id});
          }))

        }catch(error){
          console.log("Errors : ", error)
        }
        
        setLoading(false);
        if (typeof onEditSuccess == 'function') {
          onEditSuccess(response);
        }
      }).catch(response => {
        catchError(response)
      })
  
    }
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      manager.findIncludeAll(id).then((item) => {
        setLoading(false);
        validation.setValues(item.items[0]);
        setInitialUser(item.items[0].users ? item.items[0].users  : [] )
      }).catch((error)=>{
        catchError(error);
      })
    } else {
      setLoading(false);
    }
  }, []);

  /**
   * Gestion de la soumission du formulaire
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    validation.handleSubmit();
  };

  const catchError = (response: any) => {
    const currentDialog: DialogInterface = {
      show: true,
      onConfirm: () => closeDialogModal(),
      handleClose: () => closeDialogModal(),
      type: DIALOG_TYPE.ALERT
    };
    switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        currentDialog.message = '';
        if (response.data.message) {
          currentDialog.message = response.data.message;
        }
        break;
      }
      default:
    }
    setDialog(currentDialog);
  };

  /**
   */
  const closeDialogModal = () => {
    setDialog({...dialog, show: false})
  };

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("EDIT_COMPANY")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t("EDIT_COMPANY_DETAIL")}
                </CardSubtitle>
                <Form onSubmit={(e) => handleSubmitForm(e)}>
                  <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="mb-3">
                            <Label className="form-label">{props.t("NAME")} <span className="text-danger">*</span> </Label>
                            <Input
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder={props.t("ENTER_NAME")}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            />
                            {validation.touched.name && validation.errors.name ? (
                            <FormFeedback
                                type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("DESCRIPTION")} </Label>
                            <Input
                            id="description"
                            name="description"
                            className="form-control"
                            placeholder={props.t("ENTER_DESCRIPTION")}
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            />
                        </div>
                        <div className="mb-3">
                          <Label
                              className="form-label">{props.t("USERS")}</Label>
                          <AutocompleteSelectType
                              id={"users"}
                              name={"users"}
                              multiple={true}
                              choiceAttr={"username"}
                              value={validation.values.users || []}
                              remoteChoices={(inputValue:any) => {return new Promise(resolve => resolve(userRepo.searchByUsername( 1,10, inputValue).then((data) => data.items)))}}
                              placeholder={props.t("CHOOSE_USER")}
                              onChange={(e:any) => validation.handleChange(e)}
                              defaultCss={""}
                            />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary">
                      {props.t("SAVE_MODIFICATIONS")}
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
      <DialogBoxComponent
        handleClose={() => closeDialogModal()}
        handleConfirm={() => dialog.onConfirm()}
        show={dialog.show}
        type={dialog.type}
      />
    </React.Fragment>
  )
};
export default withTranslation()(EditCompany);